<template>
    <main-layout>
        <div class="main">
            <!-- main-header -->
            <div class="main__header header">
                <div class="header__wysiwyg wysiwyg">
                    <h2>Home
                    <ToolTip 
                        :type="'top'"
                        :msg="'The Home Page is for you and your team to pass team messages, setting up or celebrating a milestone? Welcoming new teammates? Anything you feel relevant to the whole team can be expressed here. Everyone on your immediate team will be in this page, and no one else will have access (no experts, investors, etc). It is absolutely private to you and your team! Enjoy and we wish you and your team amazing success in your endeavors!'"
                    />
                </h2>
                </div>
                <div class="header__actions"></div>
            </div>
            <!-- main-content -->
            <div class="main__content">
                <div class="entrepreneur">
                    <Banner></Banner>
                    <Calendar></Calendar>
                </div>
            </div>
        </div>

        <Posts></Posts>

    </main-layout>
</template>

<script>
    import store from '../store'
    import MainLayout from "../layouts/Main.vue"
    import Banner from '@/components/general/Banner'
    import Calendar from '@/components/general/Calendar'
    import Posts from '@/components/page/Posts'
    import ToolTip from '@/components/ToolTip'
    
    export default {
        name: "Home",
        components: {
            MainLayout,
            Banner,
            Calendar,
            Posts,
            ToolTip,
        },
        methods: {
        },
        created() {
            store.dispatch('GET_HOME_PAGE_DATA');
        }
    }
</script>
